import { Inject, OnInit } from '@angular/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { MultitabItem, TopTab } from 'src/app/components/common/multitab/multitab.types';
import { PanelType } from 'src/app/components/common/multitab/multitab.types';
import { ArticleService } from 'src/app/services/article.service';
import { Section as SECTIONS, Page as TABS, SubSection as SUBSECTIONS } from 'shared/location-enums';
import { Article } from 'shared/types';
import { Page, Section, SubSection } from 'shared/location-enums';
import { PATHS } from 'shared/paths';
import { randomId } from 'shared/utils/random-id';
import { DOCUMENT } from '@angular/common';
import { ExternalLinkDirective } from 'src/app/directives/external-link/external-link.directive';
import { EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';

@Component({
    selector: 'suggested-resources',
    templateUrl: './suggested-resources.component.html',
    styleUrls: ['./suggested-resources.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SuggestedResourcesComponent implements OnInit {
    mainKa: Article;
    textHeader: string;
    tabs: TopTab[] = [
        {
            title: 'Suggested Resources to Consider',
            sectionHeader: '',
            type: PanelType.normal,
            items: [] as MultitabItem[],
        },
    ] as TopTab[];

    patientResources: Article;
    constructor(private articleService: ArticleService, @Inject(DOCUMENT) private document: Document) {
        this.mainKa = this.articleService.getOne(TABS.livingWith, SECTIONS.patientResources, SUBSECTIONS.parent);

        // const subsections = [SUBSECTIONS.caregiver, SUBSECTIONS.disability, SUBSECTIONS.financial, SUBSECTIONS.international, SUBSECTIONS.crisis];
        // const accounts = this.accountService.getAccounts(TABS.livingWith, SECTIONS.resources).sort((acc1, acc2) => acc1.order - acc2.order);
        // const articles = this.articleService.getArticles(TABS.livingWith, SECTIONS.resources);
        // this.tabs[0].items = subsections.map(subsection => ({
        //     id: subsection,
        //     accounts: accounts.filter(acc => acc.subSection === subsection) as unknown as GardAccount[],
        //     title: articles.find(article => article.subSection === subsection).title,
        //     bodyText: '',
        //     nullVersion: false,
        // }));
    }

    ngOnInit() {
        this.tabs[0] = this.generateTab(this.tabs[0]);
    }

    reduceArticlesToMultitabItem(articles: Article[]): MultitabItem {
        // copy because we're going to be modifing it
        const multitabItem: MultitabItem = {
            id: '',
            panelTitle: '',
            title: '',
            bodyText: '',
            nullVersion: false,
        };

        articles.forEach((a: Article, index: number) => {
            // for Multi-Tab Buttons
            // add title if should be shown
            if (multitabItem.panelTitle === '' && a.displayTitle) {
                multitabItem.panelTitle = a.title;
            }

            // add the body, if not blank
            if (a.bodyText) {
                multitabItem.bodyText = multitabItem.bodyText + `<p class='ka-spacer mt-3'></p>${a.bodyText}`;
            }
        });
        return multitabItem;
    }

    removeAccounts(articles: Article[]): Article[] {
        articles.map(a => JSON.parse(JSON.stringify(a)));
        return articles.map((a: Article) => {
            a.accounts = [];
            return a;
        });
    }

    generateTab(tab: TopTab): TopTab {
        const financial: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.financial, undefined, undefined, false),
        );
        const managing: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.managing, undefined, undefined, false),
        );
        const dayToDay: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.dayToDay, undefined, undefined, false),
        );
        const international: Article[] = this.removeAccounts(
            this.articleService.getArticles(Page.livingWith, Section.patientResources, SubSection.international, undefined, undefined, false),
        );

        tab.items.push(this.reduceArticlesToMultitabItem(financial));
        tab.items.push(this.reduceArticlesToMultitabItem(managing));
        tab.items.push(this.reduceArticlesToMultitabItem(dayToDay));
        tab.items.push(this.reduceArticlesToMultitabItem(international));

        return tab;
    }

    hideTabs = true;

    public imgPath = PATHS.articleImages;
    public panelType = PanelType;
    public tabIndex = 0; // top tab index
    public stIndex = 0; // left tab current index (sub)
    public mstIndex = -1; // mobile sub tab index (sub)
    public sstIndex = -1; // right tab index (sub sub)
    public prefix = randomId();

    onTabClick(i: number) {
        this.tabIndex = i;
        this.stIndex = 0;
        this.mstIndex = -1;
        this.sstIndex = -1;
    }

    onSubTabClick(i: number) {
        this.stIndex = i;
        this.sstIndex = -1;
    }

    onMobileSubTabClick(i: number) {
        this.mstIndex = this.mstIndex > -1 ? -1 : i;
        this.sstIndex = -1;
    }

    toggleSubSub(i: number) {
        this.sstIndex = this.sstIndex > -1 ? -1 : i;
    }

    isLinkInternal(link: string) {
        return ExternalLinkDirective.isLinkInternal(link, this.document.location.host);
    }

    onOutsideRedirect(ev: MouseEvent, link: string) {
        if (link && !ExternalLinkDirective.isLinkInternal(link, this.document.location.host)) {
            ev.preventDefault();
            ev.stopPropagation();
            if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
                const tab = window.open(link, '_blank');
                tab.opener = null;
            }
        }
    }
}
