import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TAG_CATEGORIES } from '../../../shared/constants';
import { PATHS } from '../../../shared/paths';
import { Tag, Disease, DiseaseTrimmed } from '../../../shared/types';
import { SalesforceDiseaseDto } from '../../../data-import/src/dto/disease/salesforce-disease.dto';
import { Feature, Gene } from '../../../shared/salesforce-types';
import { TitleCaseWordPipe } from '../pipes/title-case-word.pipe';
import { SalesforceFeatureDto } from 'data-import/src/dto/disease/salesforce-symptom.dto';
import { SalesforceDiseaseTagDto } from 'data-import/src/dto/disease/salesforce-disease-tag.dto';

@Injectable({
    providedIn: 'root',
})
export class DiseaseService {
    static titleCaseWordPipe: TitleCaseWordPipe = new TitleCaseWordPipe();

    private pDisease: Disease;
    get disease(): Disease {
        return this.pDisease;
    }

    get diseaseId(): number {
        return this.disease.id;
    }

    private allDiseases: DiseaseTrimmed[];
    get trimmedDiseases(): DiseaseTrimmed[] {
        return this.allDiseases;
    }

    private pvtDiseaseCategories: Tag[];
    get diseaseCategories(): Tag[] {
        return this.pvtDiseaseCategories;
    }

    constructor(private http: HttpClient) {}

    static getMaxValue(max = '', nextRecord = ''): string {
        const nextRecordAsNumber = nextRecord.toString().replace(/\D/g, '');
        const maxAsNumber = max.toString().replace(/\D/g, '');
        if (nextRecordAsNumber > maxAsNumber) {
            return nextRecord;
        } else {
            return max;
        }
    }

    static doesDiseaseHaveTag(disease: Disease, tag: string): boolean {
        return disease.tagsDiseaseCategory.map(t => t.toLowerCase()).includes(tag.toLowerCase());
    }

    static doesDiseaseHaveDiseaseCategory(disease: Disease, category: string): boolean {
        return (disease.tagsCause || []).includes(category);
    }

    static convertSalesforceDataToDisease(sfDisease: SalesforceDiseaseDto): Disease {
        const diseaseCategories: string[] = [];

        const diseaseCategoriesWithDescriptions: Tag[] = [];

        sfDisease.GARD_Disease_Tag__c?.forEach((salesforceDiseaseTagDto: SalesforceDiseaseTagDto) => {
            if (salesforceDiseaseTagDto.Tag_Category__c.split(';').includes(TAG_CATEGORIES.diseaseCategory)) {
                diseaseCategories.push(salesforceDiseaseTagDto.Tag_Name__c);
            }

            // only if we have a description, otherwise blank tooltip
            if (salesforceDiseaseTagDto.category_description) {
                diseaseCategoriesWithDescriptions.push({
                    name: salesforceDiseaseTagDto.Tag_Name__c,
                    nameCurated: salesforceDiseaseTagDto.curated_tag_name,
                    textSnippet: salesforceDiseaseTagDto.category_description,
                } as Tag);
            }
        });

        let tagsDiseaseCategory = [];
        if (Object.hasOwn(sfDisease, 'tags') && Object.hasOwn(sfDisease, 'Disease Category')) {
            tagsDiseaseCategory = sfDisease?.tags['Disease Category'];
        }

        let tagsSpecialist = [];
        if (Object.hasOwn(sfDisease, 'tags') && Object.hasOwn(sfDisease.tags, 'Specialist')) {
            tagsSpecialist = sfDisease?.tags['Specialist'];
        }

        let tagsCause = [];
        if (Object.hasOwn(sfDisease, 'tags') && Object.hasOwn(sfDisease.tags, 'Cause')) {
            tagsCause = sfDisease?.tags['Cause'];
        }

        const disease: Partial<Disease> = {
            id: sfDisease.id,
            name: sfDisease.GARD_Name__c,
            synonymsList: sfDisease.synonyms.join('; '),
            clinicalDescription: sfDisease.Curated_Disease_Description__c,
            drugs: sfDisease.GARD_Disease_Drug__c || [],
            diagnosis: sfDisease.Diagnosis__c || [],
            caseEstimateUsa: sfDisease.Curated_USA_Estimate__c || '',
            tagsDiseaseCategory: diseaseCategories,
            tagsSpecialist,
            tagsCause,
            ageAtOnsetSnippet: sfDisease.Age_at_Onset_Snippet_Text__c,
            inheritance: sfDisease.Inheritance__c || [],
            diseaseCategoriesWithDescriptions,
        } as Disease;

        disease.organizationSupported = (sfDisease.Organization_Supported_Diseases__c || []).map(attribute => ({
            id: attribute.Id,
            name: attribute.Account_Name__c,
            url: attribute.Website__c,
            providedServices: (attribute.Provided_Resources_Services__c || '').split(';').filter((service?: string) => service),
        }));

        disease.ageAtOnset = (sfDisease.Age_At_Onset__c || []).map(attribute => ({
            value: attribute.Age_At_Onset__c,
            attributionSource: attribute.AgeAtOnset_AttributionSource__c,
        }));

        disease.ageAtDeath = (sfDisease.Age_At_Death__c || []).map(attribute => ({
            value: attribute.Age_At_Death__c,
            attributionSource: attribute.AgeAtDeath_AttributionSource__c,
        }));

        // get all features
        const diseaseFeatures: SalesforceFeatureDto[] = sfDisease.GARD_Disease_Feature__c || [];

        // define two kinds
        disease.symptoms = [];
        disease.labs = [];
        disease.features = [];

        diseaseFeatures.forEach((attribute: SalesforceFeatureDto) => {
            const feature: Feature = {
                HPO_NAME_MAPPING__c: attribute.HPO_NAME_MAPPING__c,
                HPO_Name_map__c: attribute.HPO_Name_map__c,
                Source_Curie__c: attribute.Source_Curie__c,
                HPO_Frequency__c: attribute.HPO_Frequency__c,
                HPO_Method__c: attribute.HPO_Method__c,
                HPO_Name__c: attribute.Feature__r?.HPO_Name__c || '',
                HPO_Category__c: attribute.Feature__r?.HPO_Category__c,
                HPO_Synonym__c: attribute.Feature__r?.HPO_Synonym__c,
                HPO_Description__c: attribute.Feature__r?.HPO_Description__c?.replace(/\\"/g, '"'),
                Feature_System__c: attribute.Feature__r?.Feature_System__c,
                Feature_Type__c: attribute.Feature__r?.HPO_Feature_Type__c || '',
            } as Feature;

            // if this feature is a symptom
            if (feature.Feature_Type__c.toLowerCase() === 'symptom') {
                disease.symptoms.push(feature);
            }

            // if this feature is a lab test
            else if (feature.Feature_Type__c.toLowerCase() === 'lab') {
                disease.labs.push(feature);
            }

            // if this feature is not a category, but instead a compound key
            else {
                disease.features.push(feature);
            }
        });

        disease.externalIdentifiers = (sfDisease.External_Identifier_Disease__c || []).map(source => ({
            value: source.Display_Value__c,
            url: source.URL__c,
            source: source.Source__c,
        }));

        disease.genes = (sfDisease.GARD_Disease_Gene__c || []).map(
            gene =>
                ({
                    dzGeneAssociation: gene.DzGeneAssociation__c,
                    geneSymbol: gene.GeneSymbol__c,
                    geneType: gene.Gene_Type__c,
                    dzGeneAssociationAttributionSource: gene.DzGeneAssociation_AttributionSource__c,
                    geneCausal: gene.Causal_Gene__c,
                } as Gene),
        );

        // Same transforms that we have on the trimmed file

        if (!Object.hasOwn(disease, 'spanishId')) {
            disease.spanishId = disease.spanishId || 0;
        }

        if (!Object.hasOwn(disease, 'spanishName')) {
            disease.spanishName = disease.spanishName || '';
        }

        if (!Object.hasOwn(disease, 'synonyms')) {
            disease.synonyms = disease.synonyms || [];
        }

        if (!Object.hasOwn(disease, 'diseaseCategories')) {
            disease.tagsDiseaseCategory = disease.tagsDiseaseCategory || [];
        }

        return disease as Disease;
    }

    fetch(diseaseId: number): Observable<Disease> {
        return this.http.get<SalesforceDiseaseDto>(`${PATHS.diseasePageApi + diseaseId}.json`).pipe(
            map(d => DiseaseService.convertSalesforceDataToDisease(d)),
            take(1),
            tap(disease => {
                this.pDisease = disease;
            }),
        );
    }

    fetchDiseasesCategories(): Observable<Tag[]> {
        return this.http.get<Tag[]>(PATHS.diseasesCategoriesApi).pipe(
            take(1),
            tap(dc => {
                this.pvtDiseaseCategories = dc;
            }),
        );
    }

    fetchTrimmed(): Observable<DiseaseTrimmed[]> {
        return this.http.get<DiseaseTrimmed[]>(PATHS.diseasesTrimmedApi).pipe(
            map((diseaseTrimmedRecords: DiseaseTrimmed[]) => {
                // Add all optional params in this step
                // do this here to save space in download, instead of adding empty keys to file

                diseaseTrimmedRecords.map((diseaseTrimmedRecord: DiseaseTrimmed) => {
                    if (!Object.hasOwn(diseaseTrimmedRecord, 'spanishId')) {
                        diseaseTrimmedRecord.spanishId = diseaseTrimmedRecord.spanishId || 0;
                    }

                    if (!Object.hasOwn(diseaseTrimmedRecord, 'spanishName')) {
                        diseaseTrimmedRecord.spanishName = diseaseTrimmedRecord.spanishName || '';
                    }

                    if (!Object.hasOwn(diseaseTrimmedRecord, 'synonyms')) {
                        diseaseTrimmedRecord.synonyms = diseaseTrimmedRecord.synonyms || [];
                    }

                    if (!Object.hasOwn(diseaseTrimmedRecord, 'diseaseCategories')) {
                        diseaseTrimmedRecord.tagsDiseaseCategory = diseaseTrimmedRecord.tagsDiseaseCategory || [];
                    }

                    return diseaseTrimmedRecord;
                });

                return diseaseTrimmedRecords;
            }),
            tap((diseaseTrimmedRecords: DiseaseTrimmed[]) => {
                this.allDiseases = diseaseTrimmedRecords;
            }),
        );
    }
}
