import { ElementRef, OnDestroy, OnInit, ViewChild, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Route, Router, Routes, UrlSegment } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getDiseasePath } from 'shared/utils/get-disease-path';
import { diseasesToDiseasesWithSynonymString, globalDiseaseSearch } from '../disease-search';
import { Disease, Article, DiseaseTrimmed } from 'shared/types';
import { Page as P, Page, Section as S, Section } from 'shared/location-enums';
import { MESSAGE_KEYS, WEBSITE_SECTIONS, GRID_BREAKPOINTS } from 'shared/constants';
import { PATHS } from 'shared/paths';
import { LABELS } from '../../../../../shared/labels';
import { EspanolService } from '../../disease-es/espanol.service';
import { MessageBusService } from 'src/app/services/message-bus.service';
import { LANGUAGE } from '../../../../../shared/injection-tokens';
import { Language } from '../../../../../shared/salesforce-types';
import { ArticleService } from 'src/app/services/article.service';
import { DiseaseService } from 'src/app/services/disease.service';

const SECTION_TO_CSS_CLASS = {
    [WEBSITE_SECTIONS.home]: 'home',
    [WEBSITE_SECTIONS.diseases]: 'browse',
    [WEBSITE_SECTIONS.about]: 'about',
    [WEBSITE_SECTIONS.contactUs]: 'contact',
    [WEBSITE_SECTIONS.privacyPolicy]: 'privacyPolicy',
    [WEBSITE_SECTIONS.vulnerability]: 'vulnerabilityPolicy',
    [WEBSITE_SECTIONS.foia]: 'foia',
    [WEBSITE_SECTIONS.accessibility]: 'accessibility',
    [WEBSITE_SECTIONS.disclaimer]: 'disclaimer',
};

@Component({
    selector: 'main-banner',
    templateUrl: './main-banner.component.html',
    styleUrls: ['./main-banner.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MainBannerComponent implements OnDestroy, OnInit {
    @Input() title: string;
    @Input() description: string;
    @Input() disease: Disease;
    @Input() halfHeight = false;
    @Input() spanishName: string | boolean = false;
    @ViewChild('desktopInput') desktopInput: ElementRef<HTMLInputElement>;
    @ViewChild('mobileInput') mobileInput: ElementRef<HTMLInputElement>;

    public synonymList: string;
    public breadcrumbs: { label: string; link: string }[] = [];
    public lastBreadcrumbLabel: string;
    public backUrl: string;
    public spanishUrlResult: string | boolean = false;
    public spanishUrl: string;
    public englishURL: string | boolean = false;
    public isSearchOpen: boolean = false;
    public headerClass: string = '';
    public pageLanguage: string;
    public englishToSpanishUrl: string;
    public spanishToEnglishUrl: string;
    public showPageTranslateBtn = false;
    public translateButtonTitle: string;
    public urlSegments: UrlSegment[];
    private subs = new Subscription();
    article: Article;

    imageUrl: string;

    public diseases: DiseaseTrimmed[] = [];
    public welcomeArticle: Article;
    public inputTextArticle: Article;

    languageRedirect: string;
    languageBtnText: string;

    private destroySubject = new Subject();
    private readonly resultsLimit = 5;

    search: string = '';

    constructor(
        private router: Router,
        private espanolService: EspanolService,
        private messageBusService: MessageBusService,
        public route: ActivatedRoute,
        @Inject(LANGUAGE) public language: BehaviorSubject<Language>,
        public articleService: ArticleService,
        private diseaseService: DiseaseService,
    ) {
        const sectionId = route.snapshot.data.sectionId || '';
        this.headerClass = SECTION_TO_CSS_CLASS[sectionId] || '';

        this.pageLanguage = route.snapshot.data.language;

        this.urlSegments = route.snapshot.url;

        const crumbParents = route.snapshot.data.breadcrumbParents as string[];
        if (crumbParents) {
            this.breadcrumbs = MainBannerComponent.buildBreadcrumbs(crumbParents, sectionId, this.router.config, this.language.value);
        }

        this.subs.add(
            this.messageBusService.get(MESSAGE_KEYS.searchOpen).subscribe(open => {
                this.isSearchOpen = !!open;
            }),
        );
        router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.updateDiseases('');

        this.messageBusService
            .get(MESSAGE_KEYS.searchOpen)
            .pipe(takeUntil(this.destroySubject))
            .subscribe(val => {
                this.isSearchOpen = !!val;
                if (this.isSearchOpen) {
                    setTimeout(() => {
                        if (window.innerWidth < GRID_BREAKPOINTS.lg) {
                            this.mobileInput?.nativeElement.focus();
                        } else {
                            this.desktopInput?.nativeElement.focus();
                        }
                    });
                }
            });

        this.router.events.pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.hide();
        });

        // TODO: refactor for getPageArticle()
        const articles = articleService.getArticles(Page.home, Section.header);

        if (this.route.snapshot.data.language === Language.En) {
            this.welcomeArticle = articles[0];
            this.inputTextArticle = articles[1];

            this.languageBtnText = 'Español';
            this.languageRedirect = PATHS[Language.Es].base;
        } else {
            // Only one article is returned for Spanish page
            this.inputTextArticle = articles[1];
            // this.inputTextArticle = {id:'0', title: 'Buscar la enfermedad (En Inglés)', bodyText: ''};

            this.languageBtnText = 'English';
            this.languageRedirect = '/';
        }
    }

    static buildBreadcrumbs(parentSectionIds: string[], currentSectionId: string, allRoutes: Routes, language: Language) {
        const breadcrumbs = parentSectionIds
            .map(bc => MainBannerComponent.findRouteBySectionId(allRoutes, bc, language))
            .map(r => ({
                label: this.getLabel(language, r.data.sectionId),
                link: `/${r.path}`,
            }));
        breadcrumbs.push({ label: this.getLabel(language, currentSectionId), link: '' });
        return breadcrumbs;
    }

    static getLabel(language: Language, sectionId: string) {
        return LABELS[language][sectionId] || sectionId;
    }

    static findRouteBySectionId(routes: Routes, sectionId: string, language: Language): Route {
        for (const route of routes) {
            if (route.data && route.data.sectionId === sectionId && route.data.language === language) {
                return route;
            }
            if (route.children) {
                const result = MainBannerComponent.findRouteBySectionId(route.children, sectionId, language);
                if (result) {
                    return result;
                }
            }
        }
        return undefined;
    }

    get shouldShowSearch(): boolean {
        return this.isSearchOpen || this.router.url.toLocaleLowerCase() === '/' || this.router.url.toLocaleLowerCase() === '/espanol';
    }

    get shouldShowTabs(): boolean {
        let show = false;

        if (this.router.url.toLocaleLowerCase().startsWith('/' + PATHS.diseases)) {
            const arr = this.router.url.toLocaleLowerCase().split('/');
            if (arr[1] === 'diseases' && arr[2] === String(Number(arr[2]))) {
                show = true;
            }
        }

        if (this.router.url.toLocaleLowerCase().startsWith('/' + 'espanol')) {
            const arr = this.router.url.toLocaleLowerCase().split('/');
            if (arr[1] === 'espanol' && arr[2] === String(Number(arr[2]))) {
                show = false;
            }
        }

        return show;
    }

    get shouldShowHeader(): boolean {
        let show = true;

        if (this.router.url.toLocaleLowerCase() === '/' || this.router.url.toLocaleLowerCase() === '' || this.router.url.toLocaleLowerCase() === '/espanol') {
            show = false;
        }

        return show;
    }

    ngOnInit() {
        this.backUrl = this.breadcrumbs[this.breadcrumbs.length - 2]?.link;
        if (this.disease) {
            const diseaseName = this.disease.name;
            this.breadcrumbs[this.breadcrumbs.length - 1].label = diseaseName;
            this.spanishUrlResult = this.espanolService.getLinkToSpanish(this.disease.id);
            this.synonymList = this.disease.synonymsList;
            if (this.spanishUrlResult) {
                this.spanishUrl = `${this.spanishUrlResult}`;
            }
        }

        if (this.pageLanguage === Language.Es) {
            const spanishId = this.route.snapshot.params?.diseaseId;
            this.englishURL = this.espanolService.getLinkToEnglish(spanishId);
        }

        const pathArray = this.urlSegments.map(dataItem => dataItem.path);
        const path = pathArray.join('/');

        if (this.pageLanguage === Language.En) {
            for (const key in PATHS[Language.En]) {
                if (PATHS[Language.En][key] === path) {
                    this.englishToSpanishUrl = '/' + PATHS[Language.Es][key];
                    this.showPageTranslateBtn = true;
                    this.translateButtonTitle = 'Español';
                }
            }
        } else if (this.pageLanguage === Language.Es) {
            for (const key in PATHS[Language.Es]) {
                if (PATHS[Language.Es][key] === path) {
                    this.spanishToEnglishUrl = '/' + PATHS[Language.En][key];
                    this.showPageTranslateBtn = true;
                    this.translateButtonTitle = 'English';
                }
            }
        }

        this.lastBreadcrumbLabel = this.breadcrumbs[this.breadcrumbs.length - 1]?.label;
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.destroySubject.next(undefined);
        this.destroySubject.complete();
    }

    redirectToDisease(e: PointerEvent, disease: Disease) {
        e.preventDefault();
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(false);
        this.blurInputElements();
        this.router.navigate(getDiseasePath(disease));
    }

    hide() {
        this.isSearchOpen = false;
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(false);
    }

    show() {
        // Set the article for the header based on language (Different index per language)
        const articles = this.articleService.getArticles(Page.home, Section.header);
        // this.inputTextArticle = this.route.snapshot.data.language === Language.Es ? articles[0] : articles[1];

        this.isSearchOpen = true;
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(true);
    }

    private blurInputElements() {
        this.desktopInput?.nativeElement.blur();
        this.mobileInput?.nativeElement.blur();
    }

    private updateDiseases(searchText: string) {
        if (searchText.length) {
            this.search = searchText;
            this.diseases = globalDiseaseSearch(searchText, diseasesToDiseasesWithSynonymString(this.diseaseService.trimmedDiseases)).splice(0, this.resultsLimit); // limit to 5 results
        } else {
            this.diseases = [];
        }
    }

    public createDynamicScss() {
        /*
        const SECTION_TO_CSS_CLASS = {
            [WEBSITE_SECTIONS.home]: 'home',
            [WEBSITE_SECTIONS.diseases]: 'browse',
            [WEBSITE_SECTIONS.about]: 'about',
            [WEBSITE_SECTIONS.contactUs]: 'contact',
            [WEBSITE_SECTIONS.privacyPolicy]: 'privacyPolicy',
            [WEBSITE_SECTIONS.vulnerability]: 'vulnerabilityPolicy',
            [WEBSITE_SECTIONS.foia]: 'foia',
            [WEBSITE_SECTIONS.accessibility]: 'accessibility',
            [WEBSITE_SECTIONS.disclaimer]: 'disclaimer',
        }

        interface HeaderBackground {
            useSvg: boolean;
            urlDesktop?: string;
            urlMobile?: string;
        }

        const headerBackgroundSvg: HeaderBackground = {
            useSvg: true,
            urlDesktop: '',
            urlMobile: '',
        };

        interface SectionHeaderBackground {
            [key: string]: HeaderBackground;
        }

        let headerBackground: HeaderBackground;

        const sectionToHeader: SectionHeaderBackground = this.getSectionHeaders();
        headerBackground = headerBackgroundSvg;

        // dynamically grab header based on section
        if (Object.hasOwn(sectionToHeader, sectionId)) {
            this.headerBackground = sectionToHeader[sectionId];
        }

        const getSectionHeaders = (): SectionHeaderBackground => {
            const sectionToHeader: SectionHeaderBackground = {
                [WEBSITE_SECTIONS.diseases]: headerBackgroundSvg,
                [WEBSITE_SECTIONS.privacyPolicy]: headerBackgroundSvg,
                [WEBSITE_SECTIONS.vulnerability]: headerBackgroundSvg,
                [WEBSITE_SECTIONS.foia]: headerBackgroundSvg,
                [WEBSITE_SECTIONS.accessibility]: headerBackgroundSvg,
                [WEBSITE_SECTIONS.disclaimer]: headerBackgroundSvg,
            };

            if (this.route.snapshot.data.language === Language.Es) {
                // const kaHome = this.articleService.getOne(P.home, S.header, undefined, 1);
                // const headerBackgroundHome: HeaderBackground = {
                //     useSvg: false,
                //     urlDesktop: this.articleImagesPath + kaHome.images[0]?.url + '-2000.webp',
                // };
                // sectionToHeader[WEBSITE_SECTIONS.home] = headerBackgroundHome;
                // HOMEPAGE
                sectionToHeader[WEBSITE_SECTIONS.home] = this.createHeaderBackgroundRecord(P.home, S.header);

                // CONTACT
                sectionToHeader[WEBSITE_SECTIONS.contactUs] = this.createHeaderBackgroundRecord(P.contactUs, S.header);

                // ABOUT
                sectionToHeader[WEBSITE_SECTIONS.about] = this.createHeaderBackgroundRecord(P.about, S.header);

                // Browse by Diseases
                sectionToHeader[WEBSITE_SECTIONS.diseases] = this.createHeaderBackgroundRecord(P.browse, S.header);
            } else {
                // TODO: convert this to other type after content team updates web locations...
                // const kaHome = this.articleService.getOne(P.home, S.header);
                // const headerBackgroundHome: HeaderBackground = {
                //     useSvg: false,
                //     urlDesktop: this.articleImagesPath + kaHome.images[0]?.url + '-2000.webp',
                // };

                // HOMEPAGE
                sectionToHeader[WEBSITE_SECTIONS.home] = this.createHeaderBackgroundRecord(P.home, S.header);

                // CONTACT
                sectionToHeader[WEBSITE_SECTIONS.contactUs] = this.createHeaderBackgroundRecord(P.contactUs, S.header);

                // ABOUT
                sectionToHeader[WEBSITE_SECTIONS.about] = this.createHeaderBackgroundRecord(P.about, S.header);

                // Browse by Diseases
                sectionToHeader[WEBSITE_SECTIONS.diseases] = this.createHeaderBackgroundRecord(P.browse, S.header);
            }
            return sectionToHeader;
        }

        const createHeaderBackgroundRecord = (page: Page, section: Section): HeaderBackground => {
            // waiting on SF API fix - 2023.1107

            // const kaDiseases = this.articleService.getOne(page, section);
            // if (kaDiseases.images.length) {
            //     if (kaDiseases.images[0].desktopOrMobile === 'desktop') {

            //     }

            //     if (kaDiseases.images[0].desktopOrMobile === 'mobile') {

            //     }

            //     if (kaDiseases.images[0].desktopOrMobile === 'unknown') {

            //     }
            // }

            let headerBackgroundDiseases: HeaderBackground = { useSvg: true };

            // HOMEPAGE
            if (page === P.home && section === S.header) {
                headerBackgroundDiseases = {
                    useSvg: false,
                    urlDesktop: this.assetsPath + 'main-header-background.jpg',
                    urlMobile: this.assetsPath + 'main-header-background-mobile.jpg',
                };
            }

            // CONTACT
            if (page === P.contactUs && section === S.header) {
                headerBackgroundDiseases = {
                    useSvg: false,
                    urlDesktop: this.assetsPath + 'contact-us-page-background.jpg',
                    urlMobile: this.assetsPath + 'contact-us-page-background-mobile.jpg',
                };
            }

            // ABOUT
            if (page === P.about && section === S.header) {
                headerBackgroundDiseases = {
                    useSvg: false,
                    urlDesktop: this.assetsPath + 'about-page-background.jpg',
                    urlMobile: this.assetsPath + 'about-page-background-mobile.jpg',
                };
            }

            // Browse by Diseases
            if (page === P.browse && section === S.header) {
                headerBackgroundDiseases = {
                    useSvg: false,
                    urlDesktop: this.assetsPath + 'browse-disease-background.jpg',
                    urlMobile: this.assetsPath + 'browse-disease-background-mobile.jpg',
                };
            }

            return headerBackgroundDiseases;
        }
*/
    }

    redirectToSearchPage() {
        this.messageBusService.get(MESSAGE_KEYS.searchOpen).next(false);
        if (this.search && this.search.trim()) {
            this.blurInputElements();
            this.router
                .navigate(['/', PATHS.diseases], {
                    queryParams: { search: this.search },
                })
                .then()
                .catch(err => {
                    console.error(err);
                });
        }
    }
}
