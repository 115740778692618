<div class="container-fluid pt-5 pb-3">
    <div class="container-xxxl bg-white">
        <div class="row">
            <div class="col">
                <app-bar-header [textHeader]="textHeader" [theme]="theme"> </app-bar-header>

                <header-h3 [theme]="theme" [textHeader]="medTeam?.title"></header-h3>

                <div class="mt-3"></div>

                <p class="fs-md-18" [innerHtml]="medTeam?.bodyText"></p>

                <div embeddedHTML class="mt-3 fs-md-18" *ngIf="pediatric" [innerHtml]="pediatric.bodyText"></div>
            </div>
        </div>
    </div>
</div>
