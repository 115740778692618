/* eslint-disable max-len */
import { Component, Input } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { AGE_AT_ONSET, EXTERNAL_IDENTIFIERS, EXTERNAL_POPUP_TEXT_EN } from 'shared/constants';
import { Section, SubSection, SubSubsection, Page } from 'shared/location-enums';
import { Article, Disease, AgeAtOnsetAge, ExternalIdentifier } from '../../../../../../shared/types';
import { DiseaseService } from '../../../../services/disease.service';

const REDIRECT_URL = 'https://www.orpha.net/consor/cgi-bin/index.php?lng=EN';

@Component({
    selector: 'age-at-onset',
    templateUrl: './age-at-onset.component.html',
    styleUrls: ['./age-at-onset.component.scss'],
})
export class AgeAtOnsetComponent {
    public mainArticle: Article;
    isNull = false;
    disease: Disease;
    ages: AgeAtOnsetAge[];
    onsetSnippetText: string;
    orphanetLinkHtml: string;
    orphanet: string = REDIRECT_URL;
    @Input() theme: 'maroon' | 'blue' | 'white' = undefined;

    constructor(private diseaseService: DiseaseService, articleService: ArticleService) {
        this.disease = this.diseaseService.disease;
        this.ages = AgeAtOnsetComponent.getAgeValues(this.disease).ages;
        this.isNull = AgeAtOnsetComponent.isNull(this.disease);

        if (this.isNull) {
            this.mainArticle = articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.ageAtOnset, undefined, undefined, true);
        } else {
            this.mainArticle = articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.ageAtOnset, undefined, undefined, false, SubSubsection.parent);

            const snippetArticle = articleService.getOne(
                Page.about,
                Section.diseaseAtAGlance,
                SubSection.ageAtOnset,
                undefined,
                undefined,
                false,
                SubSubsection.infographDescription,
            );
            if (snippetArticle) {
                this.onsetSnippetText = snippetArticle.bodyText.replace('${AgeAtOnsetSnippetText}', diseaseService.disease.ageAtOnsetSnippet);
            }

            // Orphanet
            const evidenceOrphanets: ExternalIdentifier[] = this.diseaseService.disease.externalIdentifiers.filter(
                (externalIdentifier: ExternalIdentifier) => externalIdentifier.source === EXTERNAL_IDENTIFIERS.orphanet,
            );

            if (evidenceOrphanets.length === 1) {
                // take first matching result
                const [evidenceOrphanet] = evidenceOrphanets;

                this.orphanet = evidenceOrphanet.url;
            }

            // Orphanet link
            const orphanetLinkArticle = articleService.getOne(Page.about, Section.diseaseAtAGlance, SubSection.ageAtOnset, undefined, undefined, false, SubSubsection.resources);
            this.orphanetLinkHtml = orphanetLinkArticle.title;
            this.orphanetLinkHtml = this.orphanetLinkHtml.replace('${OrphanetLink}', ``);
        }

        // Add ability for dynamic disease
        this.mainArticle.title = this.mainArticle.title.replace('${DiseaseName}', diseaseService.disease.name);
        this.mainArticle.bodyText = this.mainArticle.bodyText.replace('${DiseaseName}', diseaseService.disease.name);

        // also add ability to use Age at Onset Snippet
        this.mainArticle.bodyText = this.mainArticle.bodyText.replace('${AgeAtOnsetSnippetText}', diseaseService.disease.ageAtOnsetSnippet);
    }

    static isNull(disease: Disease): boolean {
        const ret = AgeAtOnsetComponent.getAgeValues(disease);
        let isNull = ret.isNull;

        // if snippet is blank
        if (!disease.ageAtOnsetSnippet) {
            isNull = true;
        }

        return isNull;
    }

    static getAgeValues(disease: Disease) {
        const ages: AgeAtOnsetAge[] = [
            { icon: 'icon-gard-antenatal', age: 'antenatal', active: false, title: 'Prenatal', year: 'Before Birth', connected: false },
            { icon: 'icon-gard-newborn', age: 'neonatal', active: false, title: 'Newborn', year: 'Birth-4 weeks', connected: false },
            { icon: 'icon-gard-infancy', age: 'infancy', active: false, title: 'Infant', year: '1-23 months', connected: false },
            { icon: 'icon-gard-childhood', age: 'childhood', active: false, title: 'Child', year: '2-11 years', connected: false },
            { icon: 'icon-gard-adolescent', age: 'adolescent', active: false, title: 'Adolescent', year: '12-18 years', connected: false },
            { icon: 'icon-gard-adult', age: 'adult', active: false, title: 'Adult', year: '19-65 years', connected: false },
            { icon: 'icon-gard-elderly', age: 'elderly', active: false, title: 'Older Adult', year: '65+ years', connected: false },
        ];

        for (let i = 0, l = ages.length; i < l; i++) {
            const dage = ages[i];

            // check if circle should be highlighted
            dage.active = disease.ageAtOnset.some(
                ageOfOnset => ageOfOnset.value.toLowerCase() === dage.age.toLowerCase() || ageOfOnset.value.toLowerCase() === AGE_AT_ONSET.allAges.toLowerCase(),
            );

            // check if line between two circles should be highlighted
            if (i > 0 && ages[i - 1].active && ages[i].active) {
                ages[i - 1].connected = true;
            }
        }

        const allInactive = ages.every(age => !age.active);

        return { isNull: allInactive, ages };
    }

    redirectToOrphanet(ev: MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        if (window.confirm(EXTERNAL_POPUP_TEXT_EN)) {
            const page = window.open(this.orphanet, '_blank');
            page.opener = null;
        }
    }
}
