<div class="suggested-resources position-relative">
    <single-ka-left-only-lightgray [article]="mainKa"></single-ka-left-only-lightgray>

    <div class="container-fluid bottom overflow-hidden position-relative" *ngIf="tabs?.length">
        <div class="top-container container-xxxl">
            <ul class="nav nav-tabs top-level-nav border-0 mx-n4" [ngClass]="{ hide: tabs?.length === 1 || hideTabs }" role="tablist">
                <li class="nav-item" *ngFor="let tab of tabs; let t = index">
                    <button
                        class="nav-link border-0 text-center fs-18 fs-md-4"
                        type="button"
                        role="tab"
                        (click)="onTabClick(t)"
                        [ngClass]="{ active: tabIndex === t }"
                        [attr.id]="'diag-panel-control' + t"
                        [attr.aria-controls]="'diag-panel' + t"
                        [attr.aria-selected]="tabIndex === t"
                    >
                        {{ tab.title }}
                    </button>
                </li>
            </ul>
        </div>

        <div class="bottom-container bg-lightblue row">
            <div class="container-xxxl px-4 py-4 py-lg-0 my-2 my-lg-0">
                <div class="tab-content main-pills">
                    <div
                        class="tab-pane show"
                        [ngClass]="{ active: t === tabIndex, show: t === tabIndex }"
                        *ngFor="let tab of tabs; let t = index"
                        [attr.id]="'diag-panel' + t"
                        role="tabpanel"
                        [attr.aria-labelledby]="'diag-panel-control' + t"
                    >
                        <!-- desktop view -->
                        <div class="d-none d-lg-block" *ngIf="tab.type === panelType.normal">
                            <div class="row py-5">
                                <div class="col-6 px-0">
                                    <h2 class="text-blue panel-title" *ngIf="tab.sectionHeader" [innerHTML]="tab.sectionHeader"></h2>
                                    <div class="nav flex-column nav-pills v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <button
                                            class="nav-link fs-18"
                                            type="button"
                                            role="tab"
                                            *ngFor="let pill of tab.items; let st = index"
                                            (click)="onSubTabClick(st)"
                                            [ngClass]="{ active: st === stIndex, first: st === 0 }"
                                            [attr.id]="'diag-pill-control' + t + '-' + st"
                                            [attr.aria-selected]="st === stIndex ? 'true' : 'false'"
                                            [attr.aria-controls]="'diag-pill' + t + '-' + st"
                                        >
                                            {{ pill.panelTitle }}
                                        </button>
                                    </div>
                                </div>

                                <div class="col-6 px-0">
                                    <div class="tab-content h-100">
                                        <div
                                            class="tab-pane h-100 position-relative"
                                            role="tabpanel"
                                            *ngFor="let subpanel of tab.items; let st = index"
                                            [ngClass]="{ active: st === stIndex, show: st === stIndex }"
                                            [attr.id]="'diag-pill' + t + '-' + st"
                                            [attr.aria-labelledby]="'diag-pill-control' + t + '-' + st"
                                            [attr.aria-hidden]="st === stIndex ? 'false' : 'true'"
                                        >
                                            <div class="d-flex flex-row w-100" role="tablist" aria-orientation="vertical">
                                                <div class="subpanel-content fs-md-18">
                                                    <h4 class="text-blue panel-title fs-5 fs-md-22" *ngIf="subpanel.panelTitle">{{ subpanel.panelTitle }}</h4>
                                                    <div class="panel-text text-link" [innerHTML]="subpanel.bodyText"></div>
                                                    <div *ngIf="subpanel.additionalArticles?.length">
                                                        <div *ngFor="let resource of subpanel.additionalArticles">
                                                            <h4 *ngIf="resource.displayTitle" class="text-blue panel-title fs-5 fs-md-22 mt-5 mb-4">
                                                                {{ resource.title }}
                                                            </h4>
                                                            <div embeddedHtml class="text-link mb-4" [innerHTML]="resource.bodyText"></div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="subpanel.bullets" class="mt-4">
                                                        <p *ngFor="let bullet of subpanel.bullets" class="my-4">
                                                            {{ bullet }}
                                                        </p>
                                                    </div>

                                                    <div *ngIf="accountsTitle && subpanel.accounts.length" class="accounts-title">
                                                        {{ accountsTitle }}
                                                    </div>

                                                    <div
                                                        class="account-panel text-link"
                                                        *ngFor="let account of subpanel.accounts; let acc = index; first as isFirst"
                                                        [ngClass]="{ first: isFirst, last: acc === subpanel.accounts.length - 1 }"
                                                    >
                                                        <a
                                                            class="account-heading-link d-flex"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            [href]="account.website"
                                                            (click)="onOutsideRedirect($event, account.website)"
                                                        >
                                                            <div class="icon-wrapper">
                                                                {{ account.name.split(' ').slice(0, -1).join(' ') }}
                                                                <!-- Display all words except the last -->
                                                                <span class="last-word dont-wrap">
                                                                    {{ account.name.split(' ').pop() }}
                                                                    <!-- Display only the last word -->
                                                                    <small *ngIf="!account.website.includes('.gov')" class="icon-gard-open-link"></small>
                                                                </span>
                                                            </div>
                                                        </a>
                                                        <p class="mt-3">{{ account.Additional_Posted_Information__c }}</p>
                                                    </div>

                                                    <a
                                                        role="button"
                                                        *ngIf="subpanel.link"
                                                        (click)="subpanel.link.onClick()"
                                                        class="text-link fw-bolder"
                                                        [innerText]="subpanel.link.title"
                                                    ></a>

                                                    <div
                                                        class="mt-4 text-center bg-greyish rounded-8 image-wrap"
                                                        *ngIf="subpanel.images?.length > 0 && subpanel.images[0].url !== '0693d000006zZLNAA2'"
                                                    >
                                                        <img [src]="imgPath + subpanel.images[0].url + '.png'" [alt]="subpanel.images[0].altText" class="img-fluid" />
                                                    </div>

                                                    <div class="nav flex-column nav-pills w-100" role="tablist" aria-orientation="vertical">
                                                        <button
                                                            class="nav-link fs-5"
                                                            type="button"
                                                            (click)="toggleSubSub(sst)"
                                                            *ngFor="let subsub of subpanel.items; let sst = index"
                                                            [attr.aria-expanded]="sst === sstIndex ? 'true' : 'false'"
                                                            [attr.aria-controls]="'subsubtab' + t + '-' + st + '-' + sst"
                                                        >
                                                            {{ subsub.title }}
                                                        </button>
                                                        <div
                                                            class="position-absolute panel-overlap bg-lightblue pe-3 collapse overflow-auto"
                                                            [ngClass]="{ show: sst === sstIndex }"
                                                            *ngFor="let subsub of subpanel.items; let sst = index"
                                                            [attr.id]="'subsubtab' + t + '-' + st + '-' + sst"
                                                            [attr.aria-hidden]="sst === sstIndex ? 'false' : 'true'"
                                                        >
                                                            <div class="d-flex flex-row w-100 overflow-auto">
                                                                <div class="back-button-container">
                                                                    <img
                                                                        src="assets/back-arrow.svg"
                                                                        alt="Back"
                                                                        class="fs-18 text-green ps-4 pt-3"
                                                                        type="button"
                                                                        (click)="toggleSubSub(sst)"
                                                                        aria-expanded="false"
                                                                        [attr.aria-controls]="'subsubtab' + t + '-' + st + '-' + sst"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h4 class="text-blue panel-title fs-5 fs-md-22">{{ subsub.title }}</h4>
                                                                    <div class="panel-text text-link" embeddedHTML [innerHTML]="subsub.bodyText"></div>
                                                                    <div class="my-4 text-center bg-greyish rounded-8 image-wrap" *ngIf="subsub.images?.length > 0">
                                                                        <img class="img-fluid" [src]="imgPath + subsub.images[0].url + '.png'" [alt]="subsub.images[0].altText" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- mobile view -->
                        <div class="d-block d-lg-none w-100 h-100 position-relative px-3 py-3" *ngIf="tab.type === panelType.normal">
                            <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                <h5 class="text-blue panel-title fw-bold fs-5 ps-3 mb-3" *ngIf="tab.sectionHeader" [innerHTML]="tab.sectionHeader"></h5>
                                <button
                                    class="nav-link"
                                    (click)="onMobileSubTabClick(st)"
                                    type="button"
                                    role="tab"
                                    *ngFor="let pill of tab.items; let st = index"
                                    [attr.aria-expanded]="st === mstIndex ? 'true' : 'false'"
                                    [attr.aria-controls]="'diag-pill-mob-' + t + '-' + st"
                                >
                                    {{ pill.panelTitle }}
                                </button>
                            </div>
                            <div
                                class="panel-fullscreen bg-lightblue p-4 h-100 collapse"
                                *ngFor="let subpanel of tab.items; let st = index"
                                [ngClass]="{ show: mstIndex === st }"
                                [attr.id]="'diag-pill-mob-' + t + '-' + st"
                            >
                                <div class="back-button-wrapper">
                                    <button
                                        class="btn text-green m-0 p-0"
                                        type="button"
                                        (click)="onMobileSubTabClick(st)"
                                        [attr.aria-expanded]="st === mstIndex ? 'true' : 'false'"
                                        [attr.aria-controls]="'diag-pill-mob-' + t + '-' + st"
                                    >
                                        <img src="assets/back-arrow.svg" class="fs-18 me-2" alt="Back" />
                                        <span class="fs-18 align-text-bottom">Back</span>
                                    </button>
                                </div>
                                <h4 class="text-blue panel-title fs-5 fs-md-22" *ngIf="subpanel.panelTitle">{{ subpanel.panelTitle }}</h4>
                                <div class="panel-text text-link" embeddedHTML [innerHTML]="subpanel.bodyText"></div>
                                <div *ngIf="subpanel.additionalArticles?.length">
                                    <div *ngIf="subpanel.additionalArticles?.length">
                                        <div *ngFor="let resource of subpanel.additionalArticles">
                                            <h4 *ngIf="resource.displayTitle" class="text-blue panel-title fs-5 fs-md-22 my-4">
                                                {{ resource.title }}
                                            </h4>
                                            <div embeddedHtml class="text-link mb-4" [innerHTML]="resource.bodyText"></div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="subpanel.bullets" class="mt-4">
                                    <p *ngFor="let bullet of subpanel.bullets" class="my-4">
                                        {{ bullet }}
                                    </p>
                                </div>

                                <a role="button" *ngIf="subpanel.link" (click)="subpanel.link.onClick()" class="text-link fw-bolder" [innerText]="subpanel.link.title"></a>

                                <div
                                    class="pt-4 mt-2 text-center bg-greyish rounded-8 image-wrap"
                                    *ngIf="subpanel.images?.length > 0 && subpanel.images[0].url !== '0693d000006zZLNAA2'"
                                >
                                    <img class="img-fluid" [src]="imgPath + subpanel.images[0].url + '.png'" [alt]="subpanel.images[0].altText" />
                                </div>
                                <div
                                    class="account-panel"
                                    *ngFor="let account of subpanel.accounts; let acc = index; first as isFirst"
                                    [ngClass]="{ first: isFirst, last: acc === subpanel.accounts.length - 1 }"
                                >
                                    <a
                                        class="account-heading-link text-link fs-18"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        [href]="account.website"
                                        (click)="onOutsideRedirect(account.website)"
                                    >
                                        <span>{{ account.Name }} </span>
                                    </a>
                                    <p>{{ account.Additional_Posted_Information__c }}</p>
                                </div>
                                <div class="nav flex-column nav-pills w-100" role="tablist" aria-orientation="vertical">
                                    <button
                                        class="nav-link"
                                        type="button"
                                        (click)="toggleSubSub(sst)"
                                        [attr.aria-expanded]="sst === sstIndex ? 'true' : 'false'"
                                        *ngFor="let subsub of subpanel.items; let sst = index"
                                        [attr.aria-controls]="'subsubtab' + t + '-' + st + '-' + sst"
                                    >
                                        {{ subsub.title }}
                                    </button>
                                    <div
                                        class="panel-fullscreen bg-lightblue p-4 h-100 collapse"
                                        *ngFor="let subsub of subpanel.items; let sst = index"
                                        [ngClass]="{ show: sst === sstIndex }"
                                        [attr.id]="'subsubtab' + t + '-' + st + '-' + sst"
                                        [attr.aria-hidden]="sst === sstIndex ? 'false' : 'true'"
                                    >
                                        <div class="back-button-wrapper">
                                            <button
                                                class="btn text-green m-0 p-0"
                                                type="button"
                                                (click)="toggleSubSub(sst)"
                                                [attr.aria-expanded]="sst === sstIndex ? 'true' : 'false'"
                                                [attr.aria-controls]="'subsubtab' + t + '-' + st + '-' + sst"
                                            >
                                                <img src="/assets/back-arrow.svg" alt="back" class="fs-18 me-2" /><span class="fs-18 align-text-bottom">Back</span>
                                            </button>
                                        </div>
                                        <div>
                                            <h4 class="text-blue panel-title fs-5 fs-md-22">{{ subsub.title }}</h4>
                                            <div class="panel-text text-link" embeddedHTML [innerHTML]="subsub.bodyText"></div>
                                            <div class="mt-4 text-center bg-greyish rounded-8 image-wrap" *ngIf="subsub.images?.length > 0">
                                                <img class="img-fluid" [src]="imgPath + subsub.images[0].url + '.png'" [alt]="subsub.images[0].altText" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- info view -->
                        <div *ngIf="tab.type === panelType.info">
                            <div class="row mt-5">
                                <div class="col-lg-6">
                                    <div class="text-center rounded-8">
                                        <img class="img-fluid" [src]="imgPath + tab.img.url + '.png'" [alt]="tab.img.altText" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="my-4 my-lg-0 mb-4" *ngFor="let item of tab.items">
                                        <h4 class="text-blue panel-title fs-5 fs-md-22" *ngIf="item.displayTitle">{{ item.title }}</h4>
                                        <p class="mt-3 panel-text text-link fs-md-18" embeddedHTML [innerHTML]="item.bodyText"></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- accounts view -->
                        <!-- desktop view -->
                        <div class="d-none d-lg-block" *ngIf="tab.type === panelType.accounts">
                            <div class="row py-5 my-4">
                                <div class="col-6 nav-pills-wrapper">
                                    <div class="nav flex-column nav-pills me-3 v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <h2 class="text-blue panel-title" *ngIf="tab.sectionHeader" [innerHTML]="tab.sectionHeader"></h2>
                                        <button
                                            class="nav-link fs-5"
                                            type="button"
                                            role="tab"
                                            *ngFor="let pill of tab.items; let st = index; first as isFirst"
                                            (click)="onSubTabClick(st)"
                                            [ngClass]="{ active: st === stIndex, first: isFirst }"
                                            [attr.id]="'diag-pill-control' + t + '-' + st"
                                            [attr.aria-selected]="st === stIndex ? 'true' : 'false'"
                                            [attr.aria-controls]="'diag-pill' + t + '-' + st"
                                        >
                                            {{ pill.title }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col-6 tab-content-wrapper">
                                    <div class="tab-content h-100">
                                        <div
                                            class="tab-pane h-100 position-relative"
                                            role="tabpanel"
                                            *ngFor="let subpanel of tab.items; let st = index"
                                            [ngClass]="{ active: st === stIndex, show: st === stIndex }"
                                            [attr.id]="'diag-pill' + t + '-' + st"
                                            [attr.aria-labelledby]="'diag-pill-control' + t + '-' + st"
                                            [attr.aria-hidden]="st === stIndex ? 'false' : 'true'"
                                        >
                                            <div class="d-flex flex-row w-100" role="tablist" aria-orientation="vertical">
                                                <div class="subpanel-content ps-5 fs-md-18">
                                                    <div
                                                        class="account-panel"
                                                        *ngFor="let account of subpanel.accounts; let acc = index; first as isFirst"
                                                        [ngClass]="{ first: isFirst, last: acc === subpanel.accounts.length - 1 }"
                                                    >
                                                        <a
                                                            link
                                                            class="account-heading-link d-flex"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            [href]="account.website"
                                                            (click)="onOutsideRedirect(account.website)"
                                                        >
                                                            <div class="icon-wrapper">
                                                                <span class="me-0">{{ account.name.split(' ').slice(0, -1).join(' ') }}</span>
                                                                <!-- Display all words except the last -->
                                                                <span class="last-word dont-wrap">
                                                                    {{ account.name.split(' ').pop() }}
                                                                    <!-- Display only the last word -->
                                                                    <small *ngIf="!account.website.includes('.gov')" class="icon-gard-open-link"></small>
                                                                </span>
                                                            </div>
                                                        </a>
                                                        <p class="mt-3">{{ account.additionalPostedInfo }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- mobile view -->
                        <div class="d-block d-lg-none w-100 h-100 position-relative" *ngIf="tab.type === panelType.accounts">
                            <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                <h5 class="text-blue panel-title" *ngIf="tab.sectionHeader" embeddedHTML [innerHTML]="tab.sectionHeader"></h5>
                                <button
                                    class="nav-link"
                                    (click)="onMobileSubTabClick(st)"
                                    type="button"
                                    role="tab"
                                    *ngFor="let pill of tab.items; let st = index"
                                    [attr.aria-expanded]="st === mstIndex ? 'true' : 'false'"
                                    [attr.aria-controls]="'diag-pill-mob-' + t + '-' + st"
                                >
                                    {{ pill.title }}
                                </button>
                            </div>
                            <div
                                class="panel-fullscreen bg-lightblue p-4 h-100 collapse"
                                *ngFor="let subpanel of tab.items; let st = index"
                                [ngClass]="{ show: mstIndex === st }"
                                [attr.id]="'diag-pill-mob-' + t + '-' + st"
                            >
                                <div class="back-button-wrapper pb-3">
                                    <button
                                        class="btn text-green m-0 p-0 fs-18"
                                        type="button"
                                        (click)="onMobileSubTabClick(st)"
                                        [attr.aria-expanded]="st === mstIndex ? 'true' : 'false'"
                                        [attr.aria-controls]="'diag-pill-mob-' + t + '-' + st"
                                    >
                                        <img src="assets/back-arrow.svg" class="fs-18 text-green bg-transparent me-2" alt="Back" />
                                        Back
                                    </button>
                                </div>
                                <div
                                    class="account-panel"
                                    *ngFor="let account of subpanel.accounts; let acc = index; first as isFirst"
                                    [ngClass]="{ first: isFirst, last: acc === subpanel.accounts.length - 1 }"
                                >
                                    <a
                                        link
                                        class="account-heading-link fs-18 d-flex"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        [href]="account.website"
                                        (click)="onOutsideRedirect(account.website)"
                                    >
                                        <div class="icon-wrapper">
                                            <span>
                                                {{ account.name }}
                                                <small *ngIf="!account.website.includes('.gov')" class="icon-gard-open-link"></small>
                                            </span>
                                        </div>
                                    </a>
                                    <p class="mt-3">{{ account.additionalPostedInfo }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
