/* eslint-disable @typescript-eslint/naming-convention, no-shadow */

export enum Page {
  // Tabs
  about = 'About',
  diagnosis = 'Diagnosis',
  research = 'Research',
  livingWith = 'LivingWith',
  all = 'All',

  // Pages
  home = 'Home',
  browse = 'Browse',
  contactUs = 'ContactUs',
  resources = 'Resources',

  // Generic
  privacyPolicy = 'PrivacyPolicy',
  foia = 'FOIA',
  disclaimer = 'Disclaimer',
  accessibility = 'Accessibility',
}

export enum Section {
  /* Parent holder */
  parent = 'Parent',

  /* about */
  gardEfforts = 'GARDEfforts',
  diseaseAtAGlance = 'DiseaseAtAGlance',
  symptoms = 'Symptoms',
  causes = 'Causes',
  nextSteps = 'NextSteps',
  aboutReferences = 'AboutReferences',
  research = 'Research',

  /* diagnosis */
  process = 'Process',
  medicalTeam = 'MedTeam',
  patientOrganizations = 'PatientOrganizations',

  getDx = 'GetDx', // old
  workingWithDoctor = 'WorkingWithDoctor',

  managementAndTreatment = 'ManageTreatment',
  diagnosisReferences = 'DiagnosisReferences',
  coordinateMedicalTeam = 'CoordinateMedicalTeam',
  findingExpert = 'FindingExpert',

  /* livingWith */
  people = 'People',
  expectations = 'Expectations',
  findOthers = 'FindOthers',
  resources = 'Resources',
  patientResources = 'PatientResources',
  livingReferences = 'LivingReferences',
  informedDecisions = 'InformedDecisions',
  patientsAndCaregivers = 'PatientsAndCaregivers',
  caregiverResources = 'CaregiverResources',
  careCenters = 'CareCenters',

  /* research */
  learn = 'Learn',
  participate = 'Participate',
  informedInvolved = 'InformedInvolved',
  partnering = 'Partnering',
  researchReferences = 'ResearchReferences',

  /* Homepage */
  gardServices = 'GARDServices',
  understand = 'Understand',
  contactUs = 'ContactUs',

  /* Site-wide */
  allReferences = 'AllReferences',
  lastUpdate = 'LastUpdate',

  header = 'Header',
  breadcrumb = 'Breadcrumb',

  /* home */
  aboutGARD = 'AboutGARD',
  notAlone = 'NotAlone',

  gardDescription = 'GARDDescription',
  policies = 'Policies',
  faq = 'FAQ',
  contactCenter = 'ContactCenter',

  gardContactCenter = 'GARDContactCenter',
}

export enum SubSection {
  /* --- all --- */
  /* breadcrumb */
  about = 'About',
  diagnosis = 'Diagnosis',
  livingWith = 'LivingWith',

  /* --- about --- */
  /* diseaseAtAGlance */
  summary = 'Summary',
  epidemiology = 'Epidemiology',
  gardInfo = 'GARDInfo',
  ageAtOnset = 'AgeOnset',
  introSnippet = 'Intro_Snippet',
  estimateSnippet = 'Estimate_Snippet',
  ageAtOnsetSnippet = 'AgeAtOnset_Snippet',
  pagSnippet = 'PAG_Snippet',
  newbornScreenSnippet = 'NewbornScreen_Snippet',
  expertDirectorySnippet = 'ExpertDirectory_Snippet',
  causeSnippet = 'Cause_Snippet',
  clinicalStudy = 'ClinicalStudy',
  clinicalStudyReferences = 'ClinicalStudyReferences',
  clinicalTrialsGov = 'ClinicalTrialsGov',
  GARDContactCenter = 'GARDContactCenter',
  homeContactFormButton = 'HomeContactFormButton',

  /* symptoms */
  sectionHeader = 'SectionHeader',
  sectionSubHeader = 'SectionSubHeader',

  /* causes */
  introduction = 'Intro',
  causeExplanation = 'CauseExplanation',
  transmission = 'Transmission',
  symptomCard = 'SymptomCard',

  /* nextSteps */
  perfect = 'Perfect',
  notPerfect = 'NotPerfect',

  /* aboutReferences */
  /* --- diagnosis --- */
  /* process */
  treatProcess = 'TreatProcess',
  engage = 'Engage',
  rareDx = 'RareDx',
  coordinateCare = 'CoordinateCare',
  careCenters = 'CareCenters',
  contactUs = 'ContactUs',
  confirmedDiagnosis = 'ConfirmedDiagnosis',
  workingDiagnosis = 'WorkingDiagnosis',
  noDiagnosis = 'NoDiagnosis',
  intro = 'Intro',
  routineUpdates = 'RoutineUpdates',
  guidance = 'Guidance',
  healthInformation = 'HealthInformation',
  collaboration = 'Collaboration',

  /* medicalTeam */
  providers = 'Providers',
  specialists = 'Specialists',
  centers = 'Centers',
  taggedSpecialist = 'TaggedSpecialist',
  otherSpecialist = 'OtherSpecialist',

  /* getDx */
  work = 'Work',
  after = 'After',
  prepare = 'Prepare',
  diagnosticTests = 'DiagnosticTests',
  talkingWithDoctor = 'TalkingWithDoctor',

  /* managementAndTreatment */
  fda = 'FDA',
  treatment = 'Treatment',
  manage = 'Manage',

  /* diagnosisReferences */
  /* --- livingWith --- */
  /* people */
  stories = 'Stories',
  prevalence = 'Prevalence',
  complementaryCare = 'ComplementaryCare',
  transitioningCare = 'TransitioningCare',
  palliativeHospice = 'PalliativeHospice',
  expandedAccess = 'ExpandedAccess',
  pediatrics = 'Pediatrics',

  /* expectations */
  challenges = 'Challenges',
  navigatingPatients = 'NavigatingPatients',
  navigatingMedical = 'NavigatingMedical',

  /* findOthers */
  overview = 'Overview',

  /* resources */
  caregiver = 'Caregiver',
  disability = 'Disability',
  financial = 'Financial',
  international = 'International',
  crisis = 'Crisis',
  managing = 'Managing',
  dayToDay = 'DayToDay',

  coping = 'Coping',
  yourself = 'Yourself',
  others = 'Others',

  /* livingReferences */
  /* --- research --- */
  /* learn */
  /* participate */
  observational = 'Observational',
  interventional = 'Interventional',
  findStudy = 'FindStudy',
  needHelp = 'NeedHelp',
  moreInfo = 'MoreInfo',

  /* informedInvolved */
  patientGroups = 'PatientGroups',
  clinicalStudies = 'ClinicalStudies',
  researchFindings = 'ResearchFindings',
  shareStory = 'ShareStory',
  joinRegistry = 'JoinRegistry',
  buildCommunity = 'BuildCommunity',
  informed = 'Informed',
  involved = 'BeInvolved',

  /* partnering */
  tookit = 'Tookit',
  radar = 'RaDaR',
  organizations = 'Organizations',

  /* about FAQ */
  diseases = 'Diseases',
  help = 'Help',

  /* HomePage */
  understandIntro = 'UnderstandIntro',
  exploreButton = 'ExploreButton',
  tabAAboutDisease = 'TabAAboutDisease',
  tabBDiagnosisTreatment = 'TabBDiagnosisTreatment',
  tabCLivingDisease = 'TabCLivingDisease',
  tabDResearch = 'TabDResearch',

  parent = 'Parent',
  contactUsContactFormButton = 'ContactUsContactFormButton',
  infographicText = 'InfographicText',
  requestForm = 'RequestForm',
  requestFormButton = 'RequestFormButton',

  browseDiseases = 'BrowseDiseases',
  browseDiseaseButton = 'BrowseDiseaseButton',
  contactUsOverview = 'ContactUsOverview',
  findResources = 'FindResources',
  findSupport = 'FindSupport',
  resources = 'Resources',

  phoneFax = 'PhoneFax',
  contactForm = 'ContactForm',

  contactCenterInfo = 'ContactCenterInfo',
}

export enum SubSubsection {
  usaEstimate = 'USAEstimate',
  usaInfo = 'USAInfo',
  newbornScreenDesc = 'NewbornScreenDesc',
  intro = 'Intro',
  button = 'Button',

  prepare = 'Prepare',
  engage = 'Engage',
  stress = 'Stress',

  medicalHx = 'MedicalHx',
  newbornScreening = 'NewbornScreening',
  geneticTesting = 'GeneticTesting',

  imaging = 'Imaging',
  mri = 'MRI',
  ct = 'CT',
  ultrasound = 'Ultrasound',
  fetalUltrasound = 'FetalUltrasound',
  petscan = 'PETscan',
  echo = 'Echo',

  procedures = 'Procedures',
  ekg = 'EKG',
  eeg = 'EEG',
  emg = 'EMG',
  nerveCondition = 'NerveCondition',
  pft = 'PFT',

  laboratory = 'Laboratory',

  parent = 'Parent',
  resources = 'Resources',
  infographDescription = 'InfographDescription',
  causalGene = 'CausalGene',
}

export enum ArticleType {
  dynamic = 'Dynamic Prose',
  dynamicProse = 'Dynamic_Prose',
  static = 'Static Prose',
  staticProse = 'Static_Prose',
}
